import React from "react"
import ContactBus from "../content/ContactBus"
import Mobile from "../content/Mobile"
import Banner from "../content/ServicesCT/Banner"
import FlowCT from "../content/ServicesCT/PhotoServices"
import PhotoServices from "../content/ServicesCT/ServicesCont"
import TextContent from "../content/ServicesCT/TextContent"
import TitleServices from "../content/ServicesCT/TitleContent"
import Footer from "../layout/Footer"
import SEO from "../SEO"
import "./cssberhasil"
import Header from "./HeaderSub"
import Navbar from "./NavbarSub"

import Helmet from "react-helmet"
import JSberhasil from "./jsberhasil"

const LayoutServicesCT = () => {
  return (
    // <>
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class:
            "home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074",
        }}
      />
      <SEO
        title="Pharmametrics Lab | Indonesia Clinical Trial Research Company"
        description="Pharmametrics Lab, the leading contract research organization that offers monitoring and project management of clinical trial study in Indonesia "
      />
      {/*
			<div id="loftloader-wrapper" className="pl-imgloading" data-show-close-time="15000">
                <div className="loader-inner">
                    <div id="loader">
                        <div className="imgloading-container">
                            <span style= {{backgroundImage: `url(${LoadingLogo})`}} ></span>
                        </div>
                        <img alt="loader image" src={LoadingLogo} />
                    </div>
                </div>
                <div className="loader-section section-fade">
                </div>
                <div className="loader-close-button">
                    <span className="screen-reader-text">Close</span>
                </div>
			</div>
			*/}

      <div id="perspective">
        <Mobile />
        <div
          id="wrapper"
          className="hasbg transparent"
          style={{ paddingTop: "0px" }}
        >
          <div id="elementor-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              className="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Header />
                  <Navbar />
                </div>
              </div>
            </div>
          </div>
          <div id="elementor-sticky-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              className="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Navbar />
                </div>
              </div>
            </div>
          </div>

          <div id="page-content-wrapper" className="">
            <div className="inner">
              <div className="inner-wrapper">
                <div className="sidebar-content fullwidth">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="5459"
                    className="elementor elementor-5459"
                    data-elementor-settings="[]"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <Banner />
                        <TextContent />
                        <FlowCT />
                        <TitleServices />
                        <PhotoServices />
                        <ContactBus title="Interested with our Clinical Trial service ?" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <JSberhasil />
      {/* </> */}
    </React.Fragment>
  )
}

export default LayoutServicesCT
